/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import React, {Suspense} from 'react';
import {BrowserRouter as Router, useRoutes,} from 'react-router-dom';
import {Spin} from 'antd';
import authStore from '@/stores/auth';

import {UnSupported} from '@/components';
import Guide from '@/modules/guide/guide';
import {useWechatConfig} from '@/hooks';
import routes from './routes';
import {global as globalStyles} from './styles';
import './App.less';

const UA = typeof window !== 'undefined' && window.navigator.userAgent.toLowerCase();
const isWeixin = UA && UA.indexOf('micromessenger') > 0;
const isDev = process.env.NODE_ENV === 'development';

const allowUrls = ['/positionScan','/belongScan']

authStore.setCoverPoint({
  tailId: new Date().getTime(),
});

const MyRouter = () => {
  const element = useRoutes(routes);
  if (allowUrls.indexOf(window.location.pathname) !== -1) {
    return element;
  }
  const { loading } = process.env.REACT_APP_ONLINE ? false : useWechatConfig();
  return loading ? <Spin spinning={loading} style={globalStyles.centered} /> : element;
};

const App = () => (
  <Router basename={process.env.REACT_APP_ROUTER_BASENAME || '/'}>
    { process.env.REACT_APP_ONLINE ? <Guide /> : (isWeixin || isDev) ? (
      <Suspense fallback={<Spin spinning style={globalStyles.centered} />}>
        <MyRouter />
      </Suspense>
    ) : <UnSupported />}
  </Router>
);

export default App;
